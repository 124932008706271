import Vue from 'vue'
let uid = 0

Vue.mixin({
	beforeCreate() {
		this.uid = uid
		uid++
	},
	methods: {
		lineBreakOnBr(text) {
			return text.replace(/(?:\r\n|\r|\n)/g, '<br>');
		},
		addZero(rawItem) {
			const item = Number(rawItem);
			return item < 10 ? '0' + item : item;
		},
		mapCurrency(currency) {
			const lang = this.$i18n.locale;

			const currencies = {
				UZS: lang === 'ru' ? 'сум' : 'soʼm',
				EUR: 'EUR',
				RUB: 'RUB',
				USD: 'USD'
			}

			return currencies[currency]
		},
		requestDataAnalyticsMapper() {
			const cities = [];
			const airportCodes = [];
			let lastArrival = null;
			const airlines = new Set();
			const airlineCodes = new Set();
			const insurance = [];

			for (let direction of this.requestData.directions) {
				if (direction.departureAirportCode !== lastArrival) {
					airportCodes.push(direction.departureAirportCode);
					cities.push(direction.departureAirport);
				}

				airportCodes.push(direction.arrivalAirportCode);
				cities.push(direction.arrivalAirport);

				lastArrival = direction.arrivalAirportCode;
			}

			if (this.offer) {
				for (let direction of this.offer.directions) {
					for (let segment of direction.segments) {
						airlines.add(segment.airline.name);
						airlineCodes.add(segment.airline.code);
					}
				}
			}

			if (this.forms && this.forms.insurance && this.insurancePrograms) {
				for (let key in this.forms.insurance) {
					if (this.forms.insurance[key] === false) {
						continue;
					}

					// let [providerId, programId] = key.split('-');
					//
					// for (let program of this.insurancePrograms) {
					// 	if (program.provider.id === providerId && Number(program.id) === Number(programId)) {
					// 		insurance.push({
					// 			code: `${program.provider.id}-${program.id}`,
					// 			name: program.name,
					// 			price: program.total
					// 		});
					// 	}
					// }
				}
			}

			return {
				cities: cities,
				airportCodes: airportCodes,
				airlines: airlines,
				airlineCodes: airlineCodes,
				insurance: insurance
			}
		}
	},
})
